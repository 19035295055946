import React from "react"
import Layout from "../components/layout"

export default () => (
  <Layout>
    <h1>Have you been drinking?</h1>
    <p>You must have had 404 beers,
    because even I don't know where you're trying to go</p>
  </Layout>
)
